import React, { useMemo } from 'react';

import { PageFragment } from '~/shared/api/graphql.fragments';
import { ButtonLink, Container, Heading, Section, Slider } from '~/shared/components';
import { useLoadBlogPosts } from '~/widgets/sections/common.sections/blog/hooks/useStaticLoadBlogPosts';
import { PostBlock } from '~/widgets/sections/common.sections/blog/post/post.card';
import clsx from 'clsx';
import { SwiperSlide } from 'swiper/react';

import * as styles from './blog.module.scss';

export const BlogSection = React.memo(({ blockTitle, title, numberOfPosts, button }) => {
  const items = useLoadBlogPosts({ numberOfPosts });

  const renderedPosts = useMemo(
    () =>
      items.map(({ node: post }) => (
        <PostBlock
          key={post.title}
          {...post}
          title={post.title}
          content={post.postPreviewText}
          previewImage={post.blogPreview.picture}
          imageAltText={post.blogPreview.altText}
        />
      )),
    [items]
  );

  return (
    <>
      <PageFragment />
      <Section className={styles.blog} title={blockTitle}>
        <Container className={styles.blog__content}>
          <Heading variant={2} className={styles.blog__title}>
            {title ? title : blockTitle}
          </Heading>
          <Container className={clsx(styles.blog__list)}>{renderedPosts}</Container>
          <Container className={clsx(styles.blog__sliderWrapper, 'mobile')}>
            <Slider
              className={clsx(styles.blog__slide)}
              count={numberOfPosts}
              arrows={false}
              navigation={false}
              alwaysIndicator={true}
            >
              {renderedPosts.map((child, index) => (
                <SwiperSlide key={index}>{child}</SwiperSlide>
              ))}
            </Slider>
          </Container>
          <ButtonLink
            id={'blog-section-all-posts-button'}
            navigateTo={button.buttonLink}
            fallback={button.buttonLink}
            title={button.buttonText}
            variant={button.buttonVariant}
          />
        </Container>
      </Section>
    </>
  );
});
