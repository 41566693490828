import React from 'react';

import { Container, Heading, Image, PostDate } from '~/shared/components';
import clsx from 'clsx';
import { Link, navigate } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

import * as styles from './post-card.module.scss';

const TopPostDescriptionPage = ({ tag, dateOf }) => (
  <Container className={styles.post__descriptionTop}>
    <PostMetaBlock postCategory={tag} />
    <PostDate postDate={dateOf} />
  </Container>
);
const TopPostDescriptionList = ({ tag, dateOf }) => (
  <Container className={styles.post__descriptionTop}>
    <PostDate postDate={dateOf} />
  </Container>
);
const BottomPostDescriptionPage = ({ tag, dateOf }) => <></>;
const BottomPostDescriptionList = ({ tag }) => (
  <Container className={styles.post__descriptionBottom}>
    <PostMetaBlock postCategory={tag} />
  </Container>
);
const PostTitleBlockPage = ({ title, onClick, slug }) => (
  <Heading variant={4} onClick={onClick} title={title}>
    {title}
  </Heading>
);
const PostTitleBlockList = ({ title, onClick, slug }) => (
  <Heading variant={4} onClick={onClick} title={title}>
    <Link to={`/posts/items/${slug}`}>{title}</Link>
  </Heading>
);
const PostContentBlockPage = ({ content }) => <p>{content}</p>;
const PostContentBlockList = ({ content }) => <p>{content}</p>;

const PostMetaBlock = ({ postCategory }) =>
  postCategory?.title ? (
    <Link className={styles.description__tag} to={`/posts/categories/${postCategory.slug}`}>
      <span>{postCategory.title}</span>
    </Link>
  ) : (
    <></>
  );

export const PostListCard = (props) => (
  <PostBlock
    {...props}
    topSectionVariant={TopPostDescriptionList}
    bottomSectionVariant={BottomPostDescriptionList}
    postTitleBlockPageVariant={PostTitleBlockList}
    postContentBlockPageVariant={PostContentBlockList}
  />
);
export const PostCard = (props) => <PostBlock {...props} />;

export const PostBlock = React.forwardRef(
  (
    {
      title,
      content,
      className,
      onClick = () => {},
      previewImage,
      imageAltText = '',
      postCategory,
      publishedAt,
      slug,
      topSectionVariant: TopSectionVariant = TopPostDescriptionPage,
      bottomSectionVariant: BottomSectionVariant = BottomPostDescriptionPage,
      postTitleBlockPageVariant: PostTitleBlockPageVariant = PostTitleBlockPage,
      postContentBlockPageVariant: PostContentBlockPageVariant = PostContentBlockPage,
    },
    ref
  ) => (
    <Container className={styles.post__link} role={'link'} onClick={() => navigate(`/posts/items/${slug}`)}>
      <Container ref={ref} className={clsx(className ? className : styles.post)}>
        <Container className={styles.post__image}>
          <Image src={previewImage.localFile} alt={imageAltText} />
        </Container>
        <TopSectionVariant dateOf={publishedAt} tag={postCategory} />
        <Container className={styles.post__content}>
          <PostTitleBlockPageVariant title={title} onClick={onClick} slug={slug} />
          <PostContentBlockPageVariant content={content} />
        </Container>
        <BottomSectionVariant dateOf={publishedAt} tag={postCategory} />
      </Container>
    </Container>
  )
);

const PostCardPropTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

PostCard.propTypes = PostCardPropTypes;
PostBlock.propTypes = PostCardPropTypes;
